import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { SelfAuditsQuery, useSelfAuditsQuery } from '../../../graphql/operations/audits.generated';
import Link from 'next/link';
import { Anchor, Badge } from '@mantine/core';
import { autoSizeAll } from '../../../components/InputGrid/InputGrid';
import { EMPTY_ARRAY } from '../../../constants';
import { SelfAuditStatus } from '../../../graphql/types.generated';
import { TimeToCompleteBadge } from './TimeToCompleteBadge';
import { isNil } from 'lodash';
import { humanizeEnum } from '../../../utils/humanizeString';
import Assignee from '../../../components/Misc/Assignee';
import React from 'react';

const getSelfAuditStatusBadgeColor = (status: string | undefined) => {
  switch (status) {
    case SelfAuditStatus.FilePrepOutstanding:
      return 'red';
    case SelfAuditStatus.FilePrepComplete:
      return 'orange';
    case SelfAuditStatus.ChangesRequested:
      return 'yellow';
    case SelfAuditStatus.CompleteExcludingPolicy:
      return 'blue';
    case SelfAuditStatus.Complete:
      return 'green';
    default:
      return 'gray';
  }
};
type RowShape = SelfAuditsQuery['selfAudits'][0];

type MyAuditsGridProps = {
  assigneeId?: string;
  auditorId?: string;
  tableHeight?: string;
};

const MyAuditsGrid = ({ auditorId, assigneeId, tableHeight = '100%' }: MyAuditsGridProps) => {
  const { data: selfAuditsData } = useSelfAuditsQuery({
    variables: {
      assigneeId: assigneeId,
      auditorId: auditorId,
    },
  });

  const colDef: ColDef<RowShape>[] = [
    {
      field: 'policyholder',
      headerName: 'Policyholder',
      cellRenderer: (props: { data: RowShape }) => (
        <Link legacyBehavior href={`/u/policies/${props.data.selfAudit?.policyId}`} passHref>
          <Anchor>{props.data.policyholder.name}</Anchor>
        </Link>
      ),
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      cellRenderer: (props: { data: RowShape }) => props.data.effectiveDate,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      cellRenderer: (props: { data: RowShape }) => {
        // as an ASSIGNEE, you have 1 month to complete your work (1 month for file prep)
        // as an AUDITOR, you have 3 months to complete the entire audit
        const monthsToComplete = !isNil(assigneeId) ? 1 : 3;
        return TimeToCompleteBadge({
          effectiveDate: props.data.effectiveDate,
          monthsToComplete: monthsToComplete,
          status: props.data.selfAudit?.status,
        });
      },
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      // do not hide the auditor if the data is from the perspective of the assignee
      hide: !isNil(assigneeId),
      cellRenderer: (props: { data: RowShape }) => (
        <Assignee imageUrl={props.data.assignee?.imageUrl} name={props.data.assignee?.name} />
      ),
    },
    {
      field: 'selfAudit.auditor',
      headerName: 'Auditor',
      // do not hide the auditor if the data is from the perspective of the assignee
      hide: !isNil(auditorId),
      cellRenderer: (props: { data: RowShape }) => (
        <Assignee
          imageUrl={props.data.selfAudit?.selfAuditAuditor?.imageUrl}
          name={props.data.selfAudit?.selfAuditAuditor?.name}
        />
      ),
    },
    {
      field: 'selfAudit.status',
      headerName: 'Status',
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [
          SelfAuditStatus.FilePrepOutstanding,
          SelfAuditStatus.FilePrepComplete,
          SelfAuditStatus.ChangesRequested,
          SelfAuditStatus.CompleteExcludingPolicy,
          SelfAuditStatus.Complete,
        ],
      },
      cellRenderer: (props: { data: RowShape }) => (
        <Badge color={getSelfAuditStatusBadgeColor(props.data.selfAudit?.status)}>
          {props.data.selfAudit?.status && humanizeEnum(props.data.selfAudit?.status)}
        </Badge>
      ),
    },
  ];

  const defaultColDef: ColDef = {
    minWidth: 50,
    editable: false,
    autoHeight: true,
    resizable: true,
    sortable: true,
    menuTabs: ['filterMenuTab'],
    filter: 'agTextColumnFilter',
    // pass in additional parameters to the text filter
    filterParams: {
      buttons: ['reset', 'apply', 'clear', 'cancel'],
      debounceMs: 200,
    },
    flex: 1,
  };

  const onGridReady = (params: GridReadyEvent) => {
    autoSizeAll(params.columnApi);
  };

  return (
    <div style={{ width: '100%', height: 325 }} className="ag-theme-balham">
      <AgGridReact
        rowData={selfAuditsData?.selfAudits ?? EMPTY_ARRAY}
        columnDefs={colDef}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        suppressMenuHide={true}
        animateRows={true}
        rowBuffer={200}
      />
    </div>
  );
};

export default MyAuditsGrid;
