import { Badge } from '@mantine/core';
import dayjs from 'dayjs';
import { SelfAuditStatus } from '../../../graphql/types.generated';
import { isNil } from 'lodash';
import React from 'react';
export const TimeToCompleteBadge = ({
  effectiveDate,
  monthsToComplete,
  status,
}: {
  effectiveDate: string;
  monthsToComplete: number;
  status: SelfAuditStatus | undefined;
}) => {
  const filePrepStatuses = [SelfAuditStatus.FilePrepOutstanding, SelfAuditStatus.ChangesRequested];
  const auditStatuses = [SelfAuditStatus.FilePrepComplete];
  const statuses = monthsToComplete === 3 ? auditStatuses : filePrepStatuses;
  const today = dayjs();
  const completionDueDate = dayjs(effectiveDate)
    .add(monthsToComplete, 'month')
    .format('MM/DD/YYYY');

  if (today.isAfter(completionDueDate) && !isNil(status) && statuses.includes(status)) {
    return (
      <Badge size="sm" color="red">
        OVERDUE
      </Badge>
    );
  } else {
    return completionDueDate;
  }
};
