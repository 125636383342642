'use client';
import {
  Box,
  Button,
  Card,
  Container,
  Group,
  Stack,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  Title,
} from '@mantine/core';
import { useSession } from '../../../hooks/useSession';
import ShortcutTooltip from '../../../components/Misc/ShortcutTooltip';
import { openModal } from '@mantine/modals';
import PolicyholderDetailsForm from '../../../components/Pricing/PolicyholderDetailsForm';
import MyOpenPoliciesGrid from '../../../components/PaginatedPolicyGrid/MyOpenPoliciesGrid';
import MyAuditsGrid from './MyAuditsGrid';
import { AuditsStep } from '../../NavigationUtil';
import { useRouter } from 'next/navigation';
import { RecentlyViewedType, useRecentlyViewed } from '../../../utils/useRecentlyViewed';
import React, { useState } from 'react';

const Dashboard = () => {
  const router = useRouter();
  const session = useSession();
  const viewed = useRecentlyViewed();
  const recentlyViewedQuotes = viewed.filter((item) => item.type === RecentlyViewedType.QUOTE);
  const redirectToLastViewedQuote = () => router.push(recentlyViewedQuotes[0].url);

  const userId = session?.session?.user?.id;
  const userName = session?.session?.user?.firstName ?? session?.session?.user?.name;
  const [currentAuditTab, setCurrentAuditTab] = useState<any>(AuditsStep.FILE_PREPS);

  return (
    <Container size="xl">
      <Stack gap="xl">
        <Title pt="xs" order={3}>
          Welcome back, <i>{userName}</i> 👋
        </Title>

        <Box>
          <Stack gap="xs">
            <Card style={{ height: '400px' }}>
              <Group pb="sm" justify="space-between">
                <Title order={4}>My Policies</Title>
                <Group>
                  <ShortcutTooltip
                    key="shortcutReopenLastQuote"
                    actionName="Reopen last quote"
                    shortcut="R"
                  >
                    <Button
                      id="reopen-last-quote-policyholders"
                      variant="subtle"
                      onClick={() => redirectToLastViewedQuote()}
                    >
                      View your last quote
                    </Button>
                  </ShortcutTooltip>
                  <ShortcutTooltip
                    key="shortcutTooltip"
                    actionName="Create Policyholder"
                    shortcut="C"
                  >
                    <Button
                      key="new"
                      onClick={() =>
                        openModal({
                          modalId: 'policyholderForm',
                          title: 'New Policyholder',
                          children: <PolicyholderDetailsForm />,
                        })
                      }
                    >
                      Create Policyholder
                    </Button>
                  </ShortcutTooltip>
                </Group>
              </Group>
              <MyOpenPoliciesGrid />
            </Card>
          </Stack>
        </Box>

        <Box>
          <Stack gap="xs">
            <Card style={{ height: '450px' }}>
              <Group pb="xs">
                <Title order={4}>My Audits</Title>
              </Group>
              <Tabs
                styles={{ tabLabel: { fontWeight: 700 } }}
                value={currentAuditTab}
                onChange={(value) => setCurrentAuditTab(value)}
              >
                <TabsList>
                  <TabsTab value={AuditsStep.FILE_PREPS}>File Preps</TabsTab>
                  <TabsTab value={AuditsStep.AUDITS}>Audits</TabsTab>
                </TabsList>
                <TabsPanel value={AuditsStep.FILE_PREPS}>
                  <MyAuditsGrid assigneeId={userId} tableHeight="100%" />
                </TabsPanel>
                <TabsPanel value={AuditsStep.AUDITS}>
                  <MyAuditsGrid auditorId={userId} tableHeight="100%" />
                </TabsPanel>
              </Tabs>
            </Card>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default Dashboard;
